import { all, put, select, takeEvery } from 'redux-saga/effects';
import { ActivityStatus } from 'sharedAPI';
import { InvasivesAPI_Call } from 'hooks/useInvasivesApi';
import { selectUserSettings } from 'state/reducers/userSettings';
import UserSettings from 'state/actions/userSettings/UserSettings';
import { RecordSetType, UserRecordCacheStatus, UserRecordSet } from 'interfaces/UserRecordSet';
import Activity from 'state/actions/activity/Activity';
import { AuthActions } from 'state/actions/auth/Auth';
import { APIDocs } from 'state/actions/userSettings/APIDocs';
import { selectAuth } from 'state/reducers/auth';
import { MOBILE } from 'state/build-time-config';
import { RecordCacheServiceFactory } from 'utils/record-cache/context';
import { RepositoryMetadata } from 'utils/record-cache';

function* handle_USER_SETTINGS_TOGGLE_RECORDS_EXPANDED_REQUEST() {
  yield put(UserSettings.toggleRecordExpandSuccess());
}

function* handle_USER_SETTINGS_REMOVE_BOUNDARY_FROM_SET_REQUEST() {
  try {
    const userSettings = yield select(selectUserSettings);
    const sets = userSettings.recordSets;

    yield put(UserSettings.Boundaries.removeFromSetSuccess(sets));
  } catch (e) {
    console.error(e);
    yield put(UserSettings.Boundaries.removeFromSetFailure());
  }
}

function* handle_USER_SETTINGS_ADD_BOUNDARY_TO_SET_REQUEST(action) {
  try {
    const userSettings = yield select(selectUserSettings);
    const sets = userSettings.recordSets;
    const current = sets[action.payload.setName];

    const boundary = JSON.parse(action.payload?.searchedBoundary);
    const patchedBoundary = { ...boundary, geos: boundary?.server_id ? [] : [...boundary?.geos] };
    current.searchBoundary = patchedBoundary;

    yield put(UserSettings.Boundaries.addToSetSuccess(sets));
  } catch (e) {
    console.error(e);
    yield put(UserSettings.Boundaries.addToSetFailure());
  }
}

function* handle_USER_SETTINGS_SET_BOUNDARIES_REQUEST(action) {
  try {
    if (action.payload.boundaries !== null) {
      // can't set local storage on kml set since some are too big...
      yield put(UserSettings.Boundaries.setSuccess(action.payload));
    }
  } catch (e) {
    console.error(e);
    yield put(UserSettings.Boundaries.setFailure(action.payload));
  }
}

function* handle_USER_SETTINGS_DELETE_BOUNDARY_REQUEST(action) {
  yield put(UserSettings.Boundaries.deleteSuccess(action.payload));
}

function* handle_USER_SETTINGS_DELETE_KML_REQUEST(action) {
  try {
    // needs offline handling
    const networkReturn = yield InvasivesAPI_Call('DELETE', `/api/admin-defined-shapes/`, {
      server_id: action.payload
    });

    if (networkReturn?.ok) {
      yield put(UserSettings.KML.deleteSuccess(action.payload));
    }
  } catch (e) {
    console.error(e);
    yield put(UserSettings.KML.deleteFailure(action.payload));
  }
}

function* handle_USER_SETTINGS_GET_INITIAL_STATE_REQUEST(action) {
  const { recordSets } = yield select(selectUserSettings);
  if (!UserSettings.InitState.get.match(action)) {
    return;
  }
  const defaultRecordSet: Record<PropertyKey, Partial<UserRecordSet>> = {
    '1': {
      recordSetType: RecordSetType.Activity,
      recordSetName: 'My Drafts',
      // add draft key
      tableFilters: [
        {
          id: '1',
          field: 'form_status',
          filterType: 'tableFilter',
          filter: ActivityStatus.DRAFT,
          operator: 'CONTAINS',
          operator2: 'AND'
        }
      ],
      colorScheme: {
        Activity_Biocontrol_Collection: '#845ec2',
        Activity_Biocontrol_Release: '#845ec2',
        Activity_Monitoring_BiocontrolDispersal_TerrestrialPlant: '#845ec2',
        Activity_Monitoring_BiocontrolRelease_TerrestrialPlant: '#845ec2',
        Activity_Monitoring_ChemicalTerrestrialAquaticPlant: '#2138e0',
        Activity_Monitoring_MechanicalTerrestrialAquaticPlant: '#2138e0',
        Activity_Observation_PlantAquatic: '#399c3e',
        Activity_Observation_PlantTerrestrial: '#399c3e',
        Activity_Treatment_ChemicalPlantAquatic: '#c6c617',
        Activity_Treatment_ChemicalPlantTerrestrial: '#c6c617',
        Activity_Treatment_MechanicalPlantAquatic: '#c6c617',
        Activity_Treatment_MechanicalPlantTerrestrial: '#c6c617'
      },
      cacheMetadataStatus: UserRecordCacheStatus.NOT_ELIGIBLE,
      drawOrder: 1
    },
    '2': {
      recordSetType: RecordSetType.Activity,
      recordSetName: 'All InvasivesBC Activities',
      colorScheme: {
        Activity_Biocontrol_Collection: '#845ec2',
        Activity_Biocontrol_Release: '#845ec2',
        Activity_Monitoring_BiocontrolDispersal_TerrestrialPlant: '#845ec2',
        Activity_Monitoring_BiocontrolRelease_TerrestrialPlant: '#845ec2',
        Activity_Monitoring_ChemicalTerrestrialAquaticPlant: '#2138e0',
        Activity_Monitoring_MechanicalTerrestrialAquaticPlant: '#2138e0',
        Activity_Observation_PlantAquatic: '#399c3e',
        Activity_Observation_PlantTerrestrial: '#399c3e',
        Activity_Treatment_ChemicalPlantAquatic: '#c6c617',
        Activity_Treatment_ChemicalPlantTerrestrial: '#c6c617',
        Activity_Treatment_MechanicalPlantAquatic: '#c6c617',
        Activity_Treatment_MechanicalPlantTerrestrial: '#c6c617'
      },
      cacheMetadataStatus: UserRecordCacheStatus.NOT_ELIGIBLE,
      drawOrder: 2
    },
    '3': {
      recordSetType: RecordSetType.IAPP,
      recordSetName: 'All IAPP Records',
      color: '#21f34f',
      drawOrder: 3,
      cacheMetadataStatus: UserRecordCacheStatus.NOT_ELIGIBLE
    }
  };

  // add offline activities for mobile
  if (MOBILE) {
    defaultRecordSet['4'] = {
      recordSetType: RecordSetType.Activity,
      recordSetName: 'All Unsynced Offline Activities',
      cacheMetadataStatus: UserRecordCacheStatus.NOT_ELIGIBLE,
      drawOrder: 4,
      mapToggle: true // by default
    };

    if (!recordSets || Object.keys(recordSets).length === 0) {
      // RecordSets are empty, try to recover whats in the local database
      const service = yield RecordCacheServiceFactory.getPlatformInstance();
      const repos = yield service.listRepositories();
      repos.forEach((repo: RepositoryMetadata) => {
        // recordSet is immutable, so append it to defaultRecordSet
        if (repo.status === UserRecordCacheStatus.CACHED && !defaultRecordSet[repo.setId]) {
          const backedUpRecordSet = UserSettings.RecordSet.createDefaultRecordset(repo.recordSetType);
          backedUpRecordSet.tableFilters = repo.filterObjects.tableFilters;
          backedUpRecordSet.cacheMetadataStatus = repo.status;
          backedUpRecordSet.recordSetName = repo.setName ?? '';
          defaultRecordSet[repo.setId] = backedUpRecordSet;
        }
      });
    }
  }

  if (action.payload && action.payload.offlineAPIDocsDisplayName) {
    yield put(APIDocs.load({ displayName: action.payload.offlineAPIDocsDisplayName }));
  } else {
    yield put(APIDocs.getRequest());
  }
  yield put(Activity.Suggestions.biocontrolOnline());
  yield put(UserSettings.InitState.getSuccess({ ...defaultRecordSet, ...recordSets }));
}

function* handle_USER_SETTINGS_SET_ACTIVE_ACTIVITY_REQUEST(action) {
  yield put(UserSettings.Activity.setActiveActivityIdSuccess(action.payload));
}

function* handle_USER_SETTINGS_SET_ACTIVE_IAPP_REQUEST(action) {
  yield put(UserSettings.IAPP.setActiveSuccess(action.payload));
}

function* handle_USER_SETTINGS_SET_NEW_RECORD_DIALOG_STATE_REQUEST(action) {
  yield put(UserSettings.setNewRecordDialogueStateSuccess(action.payload));
}

function* handle_APP_AUTH_READY(action) {
  if (action.payload.authenticated) yield put(UserSettings.InitState.get());
}

function* handle_USER_SETTINGS_SET_MAP_CENTER_REQUEST(action) {
  try {
    yield put(UserSettings.Map.setCenterSuccess(action.payload));
  } catch (e) {
    console.error(e);
    yield put(UserSettings.Map.setCenterFailure);
  }
}

function* handle_GET_API_DOC_REQUEST() {
  const { displayName } = yield select(selectAuth);

  const apiDocsWithSelectOptionsResponse = yield InvasivesAPI_Call(
    'GET',
    '/api/api-docs/',
    {},
    { filterForSelectable: 'true' }
  );
  const apiDocsWithViewOptionsResponse = yield InvasivesAPI_Call('GET', '/api/api-docs/');
  if (apiDocsWithViewOptionsResponse?.ok) {
    const apiDocsWithViewOptions = apiDocsWithViewOptionsResponse.data;
    const apiDocsWithSelectOptions = apiDocsWithSelectOptionsResponse.data;

    yield put(
      APIDocs.set({
        apiDocsWithViewOptions: apiDocsWithViewOptions,
        apiDocsWithSelectOptions: apiDocsWithSelectOptions
      })
    );
    if (displayName) {
      yield put(APIDocs.save({ displayName }));
    }
  }
}

function* userSettingsSaga() {
  yield all([
    takeEvery(AuthActions.initializeComplete.type, handle_APP_AUTH_READY),
    takeEvery(APIDocs.getRequest.type, handle_GET_API_DOC_REQUEST),
    takeEvery(UserSettings.InitState.get, handle_USER_SETTINGS_GET_INITIAL_STATE_REQUEST),
    takeEvery(UserSettings.Activity.setActiveActivityId, handle_USER_SETTINGS_SET_ACTIVE_ACTIVITY_REQUEST),
    takeEvery(UserSettings.IAPP.setActive, handle_USER_SETTINGS_SET_ACTIVE_IAPP_REQUEST),
    takeEvery(UserSettings.setNewRecordDialogueState, handle_USER_SETTINGS_SET_NEW_RECORD_DIALOG_STATE_REQUEST),

    takeEvery(UserSettings.Boundaries.addToSet, handle_USER_SETTINGS_ADD_BOUNDARY_TO_SET_REQUEST),
    takeEvery(UserSettings.Boundaries.removeFromSet, handle_USER_SETTINGS_REMOVE_BOUNDARY_FROM_SET_REQUEST),
    takeEvery(UserSettings.Boundaries.set, handle_USER_SETTINGS_SET_BOUNDARIES_REQUEST),
    takeEvery(UserSettings.Boundaries.delete, handle_USER_SETTINGS_DELETE_BOUNDARY_REQUEST),
    takeEvery(UserSettings.KML.delete, handle_USER_SETTINGS_DELETE_KML_REQUEST),
    takeEvery(UserSettings.toggleRecordExpand, handle_USER_SETTINGS_TOGGLE_RECORDS_EXPANDED_REQUEST),
    takeEvery(UserSettings.Map.setCenter, handle_USER_SETTINGS_SET_MAP_CENTER_REQUEST)
  ]);
}

export default userSettingsSaga;
